import $ from 'jquery';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PaymentMode } from '../constants';
import Loading from '../components/Loading';
import { newPaymentActions, appActions } from '../data/actions';
type Props = {
  createPayment: any;
  computeHash: any;
  urlParams: any;
  routeTo: any;
  newPayment: any;
  settings: any;
};
class TapPay extends React.Component<Props> {
  static propTypes = {
    settings: PropTypes.object.isRequired,
    urlParams: PropTypes.object.isRequired,
    createPayment: PropTypes.func.isRequired,
    newPayment: PropTypes.object.isRequired,
    computeHash: PropTypes.func.isRequired,
    routeTo: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);

    this.state = {
      hash: null,
    };
  }

  componentDidMount() {
    const { createPayment, computeHash, urlParams, routeTo } = this.props;
    const { userId, amount } = urlParams;
    if (!amount) {
      return;
    }

    createPayment(
      {
        userId,
        amount,
        currency: 'KWD',
        type: 'payment',
        mode: PaymentMode.tap,
      },
      (err, payment) => {
        if (err) {
          if (window.history.length > 2) {
            window.history.back();
          } else {
            routeTo('home');
          }
        } else {
          computeHash(PaymentMode.tap, payment);
        }
      }
    );

    const i = setInterval(() => {
      const { userId } = urlParams;

      if ($('#tapForm #hash').val() && $('#tapForm [name=ItemPrice1]').val()) {
        const formData = {
          userId: userId, // You may want to make this dynamic
          paymentId: $('#tapForm [name=OrdID]').val(),
          amount: $('#tapForm [name=ItemPrice1]').val(),
          name: $('#tapForm [name=CstFName]').val(),
          email: $('#tapForm [name=CstEmail]').val()
        };

        // Use backend API to create TAP charge
        //http://localhost:3030/tap-charge
        $.ajax({
          url: `${this.props.settings.apiUrl}/tap-charge`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          data: JSON.stringify(formData),
          success: (response) => {
        
            if (response.transactionUrl) {
              window.location.href = response.transactionUrl;
            } else {
              console.error('Invalid response from TAP:', response);
            }
          },
          error: (xhr, status, error) => {
            console.error('TAP API Error:', error);
            if (window.history.length > 2) {
              window.history.back();
            } else {
              routeTo('home');
            }
          }
        });
        clearInterval(i);
      }
    }, 500);
  }

  render() {
    const { newPayment, settings } = this.props;
    const tapSettings = settings.tap;
    const { id, hashStatus, amount, user, currency } = newPayment || {};
    const { name, phone, email } = user || {};
    const { merchantId, username, returnUrl, failUrl, postUrl, paymentUrl } =
      tapSettings || {};
    const itemName = 'Boxit Storage';
    const hash = (hashStatus && hashStatus.hash) || '';
    const tapAmount = +(amount / 10000).toFixed(3);

    return (
      <div>
        <div className="row">
          <h2 className="sub-title m-t-20 m-b-20">Redirecting...</h2>
          <div className="grid grid-cols-1 mx-auto md:w-1/2">
            <div className="box center text-center p-20">
              <Loading />
              <form id="tapForm" method="post" action={paymentUrl}>
                <input
                  id="fname"
                  type="hidden"
                  name="CstFName"
                  value={name || 'Guest User'}
                />
                <input type="hidden" name="CstMobile" value={phone} />
                <input type="hidden" name="CstEmail" value={email} />
                <input type="hidden" name="ItemQty1" value="1" />
                <input type="hidden" name="ItemName1" value={itemName} />
                <input type="hidden" name="ItemPrice1" value={tapAmount} />
                <input type="hidden" name="CurrencyCode" value={currency} />
                <input type="hidden" name="OrdID" value={id} />
                <input type="hidden" name="MEID" value={merchantId} />
                <input type="hidden" name="UName" value={username} />
                <input type="hidden" name="ReturnURL" value={returnUrl} />
                <input
                  type="hidden"
                  name="FailURL"
                  value={`${failUrl}&trackid=${id}`}
                />
                <input type="hidden" name="PostURL" value={postUrl} />
                <input id="hash" type="hidden" name="hash" value={hash} />
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// TapPay.propTypes = {
//   settings: PropTypes.object.isRequired,
//   urlParams: PropTypes.object.isRequired,
//   createPayment: PropTypes.func.isRequired,
//   newPayment: PropTypes.object.isRequired,
//   computeHash: PropTypes.func.isRequired,
//   routeTo: PropTypes.func.isRequired,
// };

const TapPayContainer = connect(
  state => ({
    newPayment: (state as any).newPayment,
  }),
  dispatch =>
    bindActionCreators(
      {
        ...newPaymentActions,
        ...appActions,
      },
      dispatch
    )
)(TapPay);

export default TapPayContainer;
