import React, { Component } from 'react';
import swal from 'sweetalert2';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { appActions, requestActions, userActions } from '../data/actions';
import Button from '../components/Button';
import s from './styles.module.css';

type Props = {
  queryParams: any;
  routeTo: any;
  updateRequest: any;
  urlParams: any;
};

type State = {
  feedback: string;
};

class RequestFeedbackPage extends Component<Props, State> {
  static propTypes = {
    queryParams: PropTypes.object.isRequired,
    routeTo: PropTypes.func.isRequired,
    updateRequest: PropTypes.func.isRequired,
    urlParams: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      feedback: '',
    };

    // this.onValueChange = this.onValueChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.formSubmit = this.formSubmit.bind(this);
  }

  componentDidMount() {
    const { queryParams, routeTo, updateRequest, urlParams } = this.props;
    const requestId = urlParams.requestId;
    const rating = queryParams.rating;
    const accessToken = queryParams.accessToken;
    // if (requestId && rating && accessToken) {
    //   updateRequest(requestId, { rating, feedback: '' }, accessToken);
    // } else {
    //   routeTo('home');
    // }
  }

  handleChange(event) {
    this.setState({ feedback: event.target.value });
  }

  formSubmit(event) {
    event.preventDefault();
    const { updateRequest, urlParams, queryParams } = this.props;
    const requestId = urlParams.requestId;
    const accessToken = queryParams.accessToken;
    const feedback = this.state.feedback;
    const rating = queryParams.rating;

    if (feedback) {
      updateRequest(requestId, { feedback, rating }, accessToken);
      this.setState({ feedback: '' });
      swal.fire('Done!', 'Thank you for your feedback', 'success').then(() => {
        window.location.href = 'https://www.boxitstorage.com/';
      });
    }
  }

  render() {
    const { queryParams, routeTo } = this.props;
    const rating = queryParams.rating;
    return (
      <div>
        <h1 className="my-4 text-center text-4xl font-semibold text-gray-600 lg:my-4">
          Share your feedback
        </h1>
        <div className="box center max-w-4xl p-40 rounded shadow-md">
          <form onSubmit={this.formSubmit}>
            <h3 className="mt-20 mb-20 text-3xl">
              Do you have suggestions to improve our services?
            </h3>
            <div className={`${s.inputTag}`} style={{ width: '100%' }}>
              <textarea
                className="form-control mt-0 mb-20 border w-full"
                rows={4}
                value={this.state.feedback}
                onChange={this.handleChange}
              />
            </div>
            <div className={s.btnAlign}>
              <button
                className={`${s.btnCustom} btn w-full uppercase`}
                type="submit"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const RequestSurveyContainer = connect(
  state => ({
    order: (state as any).order,
  }),
  dispatch =>
    bindActionCreators(
      {
        ...appActions,
        ...requestActions,
        ...userActions,
      },
      dispatch
    )
)(RequestFeedbackPage);

export default RequestSurveyContainer;
