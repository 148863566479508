import React from 'react';
import PropTypes from 'prop-types';
import s from './styles.module.css';

// Interface for the props we actually use in the component
interface PaymentStatusContentProps {
  status: string;
  amount?: number | null;
  currency?: string | null;
}

// Pure presentation component with only the props it needs
const PaymentStatusContent: React.FC<PaymentStatusContentProps> = ({ status, amount, currency }) => {
  console.log('status', status, 'amount', amount, 'currency', currency);
  
  const formatCurrency = (value: number, currencyCode: string) => {
    // Use Intl.NumberFormat without currency style to format the number part
    const formattedNumber = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value);
    
    // Return the formatted number followed by the currency code
    return `${formattedNumber} ${currencyCode}`;
  };

  return (
    <div className="box center text-center p-20">
      {status === 'CAPTURED' ? (
        <>
          <i className="fa fa-5x fa-check-circle-o green m-10 m-t-0" />
          <h3 className="sub-title m-t-0 m-b-10">Payment Success</h3>
          <p className={s.statusText}>
            We have received your payment.
            {amount && currency && (
              <>
                <br />
                <strong>Amount Paid: {formatCurrency(amount, currency)}</strong>
              </>
            )}
            <br />
          </p>
        </>
      ) : (
        <div>
          <i className="fa fa-5x fa-times red m-10 m-t-0" />
          <h3 className="sub-title m-t-0 m-b-10">Payment Failed</h3>
          <p className={s.statusText}>
            We were unable to receive your payment.
            {amount && currency && (
              <>
                <br />
                <strong>Amount: {formatCurrency(amount, currency)}</strong>
              </>
            )}
            <br />
            We will get in touch with you shortly.
          </p>
        </div>
      )}
    </div>
  );
};

PaymentStatusContent.propTypes = {
  status: PropTypes.string.isRequired,
  amount: PropTypes.number,
  currency: PropTypes.string
};

// This connector component extracts data from router props and passes it to the presentation component
// Using any type to avoid TypeScript/PropTypes compatibility issues with router props
class TapPaymentStatus extends React.Component<any, any> {
  static propTypes = {
    match: PropTypes.object,
    location: PropTypes.object
  };

  render() {
    const { match, location } = this.props;
    
    // Get status from URL params
    const urlStatus = match?.params?.status;
    
    // Try to parse amount from URL if available
    const urlAmount = match?.params?.amount ? 
      parseFloat(match.params.amount) : null;
    
    // Get currency from URL params
    const urlCurrency = match?.params?.currency;
    
    // Get state from location (for data passed via routeTo)
    const stateStatus = location?.state?.status;
    const stateAmount = location?.state?.amount;
    const stateCurrency = location?.state?.currency;
    
    // Use state data if available, fallback to URL params
    const status = stateStatus || urlStatus || 'FAILED';
    const amount = stateAmount || urlAmount;
    const currency = stateCurrency || urlCurrency;
    
    console.log('TapPaymentStatus - status:', status, 'amount:', amount, 'currency:', currency);
    
    // Pass the extracted props to the presentation component
    return <PaymentStatusContent 
      status={status} 
      amount={amount} 
      currency={currency} 
    />;
  }
}

export default TapPaymentStatus;
