const config = {
  development: {
    rootUrl: process.env.ROOT_URL || '/',
    apiUrl: 'http://localhost:3030',

    facebook: {
      appId: '1040780489368292',
    },

    tap: {
      merchantId: '13014',
      username: 'test',
      retriveApi:'https://api.tap.company/v2/charges/',
      secretApiKey:'sk_test_XKokBfNWv6FIYuTMg5sLPjhJ',
      paymentUrl: 'https://api.tap.company/v2/charges/',
      postUrl: 'https://webhook.site/8f3c466c-3103-4297-b9d4-61a0e52520e2',
      returnUrl: 'http://localhost:3001/payment/tap/process',
      failUrl: 'http://localhost:3001/payment/tap/process/?result=FAILED',
    },

    payfort: {
      merchantId: 'eYgMxZmE',
      accessCode: 'YTLlEOlchUsoDsosA3if',
      saudiMerchentId: 'eYgMxZmE',
      saudiAccessCode: 'ACJKwfgKVG5EGYb0HCTg',
      paymentUrl: 'https://sbcheckout.payfort.com/FortAPI/paymentPage',
      returnUrl: 'http://localhost:3030/payfort',
      createTokenUrl: 'https://sbcheckout.payfort.com/FortAPI/paymentPage',
      createTokenReturnUrl: 'https://localhost:3030/payfort-cards',
      recurringPaymentUrl:
        'https://sbpaymentservices.payfort.com/FortAPI/paymentApi',
    },
  },

  staging: {
    rootUrl: process.env.ROOT_URL || '/',
    serverUrl: 'https://staging.boxitstorage.com',
    apiUrl: 'https://api.staging.boxitstorage.com',

    facebook: {
      appId: '1040780489368292',
    },
    tap: {
      merchantId: '1234',
      username: 'test',
      retriveApi:'https://api.tap.company/v2/charges/',
      secretApiKey:'sk_test_XKokBfNWv6FIYuTMg5sLPjhJ',
      paymentUrl: 'https://api.tap.company/v2/charges/',
      postUrl: 'https://api.staging.boxitstorage.com/tap/',
      returnUrl: 'https://app.staging.boxitstorage.com/payment/tap/process/',
      failUrl:
        'https://app.staging.boxitstorage.com/payment/tap/process/?result=FAILED',
    },
    merchantId: '7081277',
    username: '3588e@tap',
    paymentUrl: 'https://api.tap.company/v2/charges/',
    postUrl: 'https://api.boxitstorage.com/tap/',
    returnUrl: 'https://app.boxitstorage.com/payment/tap/process/',
    failUrl: 'https://app.boxitstorage.com/payment/tap/process/?result=FAILED',
    payfort: {
      merchantId: 'eYgMxZmE',
      accessCode: 'YTLlEOlchUsoDsosA3if',
      saudiMerchentId: 'eYgMxZmE',
      saudiAccessCode: 'ACJKwfgKVG5EGYb0HCTg',
      paymentUrl: 'https://sbcheckout.payfort.com/FortAPI/paymentPage',
      returnUrl: 'https://api.staging.boxitstorage.com/payfort',
      createTokenUrl: 'https://sbcheckout.payfort.com/FortAPI/paymentPage',
      createTokenReturnUrl:
        'https://api.staging.boxitstorage.com/payfort-cards',
      recurringPaymentUrl:
        'https://sbpaymentservices.payfort.com/FortAPI/paymentApi',
    },
  },

  production: {
    rootUrl: process.env.ROOT_URL || '/',
    serverUrl: 'https://api.boxitstorage.com',
    apiUrl: 'https://api.boxitstorage.com',

    facebook: {
      appId: '1040780489368292',
    },

    tap: {
      merchantId: '7081277',
      username: '3588e@tap',
      retriveApi:'https://api.tap.company/v2/charges/',
      secretApiKey:'sk_live_B0Cq2z7mNJAglPZ3p5uXi4HY',
      paymentUrl: 'https://api.tap.company/v2/charges/',
      postUrl: 'https://api.boxitstorage.com/tap/',
      returnUrl: 'https://app.boxitstorage.com/payment/tap/process/',
      failUrl:
        'https://app.boxitstorage.com/payment/tap/process/?result=FAILED',
    },

    payfort: {
      merchantId: 'jZPkFQjy',
      accessCode: 'ZtxbWWLR6gjKxHUvM6ZP',
      saudiMerchentId: 'jZPkFQjy',
      saudiAccessCode: 'L8B7gzbh5sa63n4Nm2FF',
      paymentUrl: 'https://checkout.payfort.com/FortAPI/paymentPage',// real prod(uncomment after testing):'https://checkout.payfort.com/FortAPI/paymentPage',
      returnUrl: 'https://api.boxitstorage.com/payfort',
      createTokenUrl: 'https://checkout.payfort.com/FortAPI/paymentPage',
      createTokenReturnUrl: 'https://api.boxitstorage.com/payfort-cards',
    },
  },
};

console.log('settings: ' + process.env.REACT_APP_ENV);
export default config[process.env.REACT_APP_ENV || 'development'];
