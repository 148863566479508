import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PaymentMode } from '../constants';
import Loading from '../components/Loading';
import { ACTION_START } from '../data/constants';
import { newPaymentActions, appActions } from '../data/actions';

type Props = {
  newPayment: any;
  routeTo: any;
  queryParams: any;
  urlParams: any;
  processPayment: any;
  settings: any;
};

class PaymentProcess extends React.Component<Props> {
  static propTypes = {
    processPayment: PropTypes.func.isRequired,
    routeTo: PropTypes.func.isRequired,
    urlParams: PropTypes.object.isRequired,
    queryParams: PropTypes.object.isRequired,
    newPayment: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired,
  };

  async verifyTapCharge(chargeId: string) {
    try {
      console.log('this.props.settings',this.props)
      const response = await fetch(`https://api.boxitstorage.com/tap-charge/${chargeId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      
      const chargeData = await response.json();
      return {
        isSuccess: chargeData.status === 'CAPTURED',
        amount: chargeData.amount,
        currency: chargeData.currency,
        chargeData
      };
    } catch (error) {
      console.error('Error verifying TAP charge:', error);
      return {
        isSuccess: false,
        amount: 0,
        currency: '',
        chargeData: null
      };
    }
  }

  async componentDidMount() {
    const {
      newPayment,
      routeTo,
      queryParams,
      urlParams,
      processPayment,
    } = this.props;
    
    const mode = urlParams.mode;
    let paymentId;
    console.log('Processing payment before', queryParams.tap_id);

    if (mode === PaymentMode.tap || queryParams.tap_id) {
      console.log('Processing payment via TAP');
       // Changed from trackid to tap_id
      console.log('queryParams.tap_id',queryParams.tap_id)
      paymentId = queryParams.tap_id; // Changed from trackid to tap_id
      console.log('paymentId',paymentId)
      
      // Verify the charge status with TAP API
      const chargeResult = await this.verifyTapCharge(paymentId); 
      
      // if (!isSuccess) {
      //   routeTo('payment-status', { 
      //     paymentId,
      //     status: 'failed',
      //     message: 'Payment verification failed'
      //   });
      //   return;
      // }
      
      // Directly route to tap-payment-status with URL params and state
      const status = chargeResult.isSuccess ? 'CAPTURED' : 'FAILED';
      
      // Create state object with payment details
      const paymentState = {
        status,
        amount: chargeResult.amount,
        currency: chargeResult.currency
      };
      
      // Pass the status as URL param and the rest as state
      routeTo('tap-payment-status', { status }, {}, paymentState);
      return;
    } 
    else if (mode === PaymentMode.payfort) {
      paymentId = queryParams.merchant_reference;
    }

    if (!paymentId) {
      routeTo('home');
      return;
    }

    if (
      newPayment.processStatus.status &&
      newPayment.processStatus.status !== ACTION_START
    ) {
      routeTo('payment-status', { paymentId });
      return;
    }

    // Process the payment with the verified status
    processPayment(mode, {
      ...queryParams,
      verified: mode === PaymentMode.tap ? true : undefined
    }, () => routeTo('payment-status', { paymentId }));
  }

  render() {
    return (
      <div>
        <div className="row">
          <h2 className="sub-title m-t-20 m-b-20">Processing...</h2>
          <div className="grid grid-cols-1 mx-auto md:w-1/2">
            <div className="box center text-center p-20">
              <Loading />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const PaymentProcessContainer = connect(
  state => ({
    newPayment: (state as any).newPayment,
    settings: (state as any).config.settings,
  }),
  dispatch =>
    bindActionCreators(
      {
        ...newPaymentActions,
        ...appActions,
      },
      dispatch
    )
)(PaymentProcess);

export default PaymentProcessContainer;
