import React, { useState, useEffect } from 'react';
import swal from 'sweetalert2';
import capitalize from 'capitalize';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
  appActions,
  requestActions,
  userActions,
  photoActions,
} from '../data/actions';
import { Request, Photo } from '../types';
import { RequestType, RequestStatus } from '../constants';
import Loading from '../components/Loading';
import ErrorPage from '../error';
import SignModal from './SignModal';
import RaiseIssueModal from './RaiseIssueModal';

const renderLine = (storage: Storage, index: number, photos: Photo[]) => (
  <tr key={storage.id} className="">
    <td className="px-6 py-4 whitespace-nowrap font-medium">{index + 1}</td>
    <td className="hidden px-6 py-4 whitespace-nowrap font-medium md:table-cell">
      {storage.code}
    </td>
    <td className="px-6 py-4 whitespace-nowrap font-medium">
      {storage.storagetype?.name}
      <div className="text-red-400 lg:hidden">
        {storage.damage ? 'Damaged' : null}
      </div>
    </td>
    <td className="hidden px-6 py-4 whitespace-nowrap font-medium md:table-cell">
      {storage.name}
    </td>
    <td className="hidden px-6 py-4 whitespace-nowrap font-medium md:table-cell">
      {storage.damage ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 text-red-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
            d="M5 13l4 4L19 7"
          />
        </svg>
      ) : null}
    </td>
    <td className="hidden px-6 py-4 whitespace-nowrap font-medium md:table-cell">
      {(photos || []).map(photo => (
        <div key={photo.id}>
          <img className="w-24 h-24" src={photo.thumbnail || ''} />
        </div>
      ))}
    </td>
    <td>
      <a
        href={`/storage/${storage.id}`}
        className="text-blue-500 font-semibold"
      >
        View
      </a>
    </td>
  </tr>
);

type Props = {
  queryParams: any;
  getRequest: any;
  getPhotos: any;
  updateRequest: any;
  urlParams: any;
  request: Request;
  requests: Request[];
  storages: Storage[];
  photos: Photo[];
  push: (path: string) => void;
};

const RequestNotePage: React.FC<Props> = ({
  updateRequest,
  getRequest,
  getPhotos,
  urlParams,
  requests,
  request,
  storages,
  photos,
  push,
}: Props) => {
  const [showSignModal, setShowSignModal] = useState(false);
  const [showIssueModal, setShowIssueModal] = useState(false);

  const requestId = urlParams.requestId;

  useEffect(() => {
    getRequest(requestId);
  }, [requestId]);

  useEffect(() => {
    getPhotos();
  }, [request]);

  const acceptRequest = (signature: string) => {
    const requestId = urlParams.requestId;

    if (signature) {
      updateRequest(requestId, {
        customerAction: 'accepted',
        customerSignature: signature,
      });
      swal
        .fire(
          'Done!',
          `Thank you for accepting the ${capitalize(request.type)} note`,
          'success'
        )
        .then(() => {
          push('/');
        });
    }
  };

  const rejectRequest = (complaint: string) => {
    const requestId = urlParams.requestId;

    if (complaint) {
      updateRequest(requestId, {
        dispute: true,
        customerAction: 'disputed',
        customerComplaint: complaint,
      });
      swal
        .fire('Done!', 'We will get back to you shortly', 'success')
        .then(() => {
          push('/');
        });
    }
  };

  if ((requests as any).isFetching) {
    return <Loading />;
  }

  if (
    (requests as any).error ||
    !request ||
    !(
      (request.type === RequestType.pickup &&
        [
          RequestStatus.transit,
          RequestStatus.received,
          RequestStatus.complete,
        ].indexOf(request.status) !== -1) ||
      (request.type === RequestType.drop &&
        [RequestStatus.received, RequestStatus.complete].indexOf(
          request.status
        ) !== -1) ||
      (request.type === RequestType.delivery &&
        [RequestStatus.delivered, RequestStatus.complete].indexOf(
          request.status
        ) !== -1) ||
      (request.type === RequestType.collect &&
        [RequestStatus.delivered, RequestStatus.complete].indexOf(
          request.status
        ) !== -1)
    )
  ) {
    return <ErrorPage />;
  }

  const numPieces = storages.length;
  const numItems = storages && storages.filter(i => !i.isPart).length;

  return (
    <div className="mx-4 bg-white shadow rounded-lg lg:rounded-xl">
      <div className="px-4 py-8 border-b border-gray-200 sm:px-8">
        <div className="flex items-center justify-between flex-wrap sm:flex-nowrap">
          <h1 className="text-4xl leading-6 font-medium text-gray-900">
            {capitalize(request.type)} Note
          </h1>

          {request.customerAction === 'accepted' ? (
            <span className="inline-block px-2 py-1 rounded-full bg-green-200 text-green-800 uppercase font-semibold">
              Accepted
            </span>
          ) : (
            <div className="flex-shrink-0 mt-6 lg:mt-0">
              {request.customerAction === 'disputed' ? (
                <span className="inline-block px-2 py-1 rounded-full bg-red-200 text-red-800 uppercase font-semibold">
                  Disputed
                </span>
              ) : request.customerAction === 'resolved' ? (
                <span className="inline-block px-2 py-1 rounded-full bg-blue-200 text-blue-800 uppercase font-semibold">
                  Resolved
                </span>
              ) : (
                <button
                  type="button"
                  onClick={() => setShowIssueModal(true)}
                  className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-100 focus:outline-none"
                >
                  Raise Complaint
                </button>
              )}

              <button
                type="button"
                onClick={() => setShowSignModal(true)}
                className="relative inline-flex items-center ml-4 px-4 py-2 border border-transparent shadow-sm font-medium rounded-md bg-boxit bg-boxit-light focus:outline-none active:bg-boxit text-white text-center uppercase tracking-wider font-medium"
              >
                Sign & Accept
              </button>
            </div>
          )}

          <SignModal
            open={showSignModal}
            onClose={sign => {
              setShowSignModal(false);
              acceptRequest(sign);
            }}
          />
          <RaiseIssueModal
            open={showIssueModal}
            onClose={complaint => {
              setShowIssueModal(false);
              rejectRequest(complaint);
            }}
          />
        </div>
      </div>

      <div className="px-8 py-8">
        <div className="grid gird-cols-1 md:grid-cols-2">
          <div className="grid grid-cols-2 max-w-xs">
            <div className="font-bold">{capitalize(request.type)} No</div>
            <div>{request.id}</div>
            <div className="font-bold">Total</div>
            <div>
              {numItems} items ({numPieces} pieces)
            </div>
          </div>

          <div className="grid grid-cols-2 max-w-xs">
            <div className="font-bold">Date</div>
            <div>
              {request.finishedAt && moment(request.finishedAt).format('LL')}
            </div>
            <div className="font-bold">Time</div>
            <div>
              {request.finishedAt && moment(request.finishedAt).format('LT')}
            </div>
          </div>
        </div>
      </div>

      <div className="lg:px-4 py-8">
        <table className="w-full overflow-hidden divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr className="">
              <th
                scope="col"
                className="px-6 py-3 text-left font-bold text-gray-500 uppercase tracking-wider"
              >
                No
              </th>
              <th
                scope="col"
                className="hidden px-6 py-3 text-left font-bold text-gray-500 uppercase tracking-wider md:table-cell"
              >
                Code
              </th>
              <th
                scope="col"
                className="hidden px-6 py-3 text-left font-bold text-gray-500 uppercase tracking-wider md:table-cell"
              >
                Item
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left font-bold text-gray-500 uppercase tracking-wider"
              >
                Name
              </th>
              <th
                scope="col"
                className="hidden px-6 py-3 text-left font-bold text-gray-500 uppercase tracking-wider md:table-cell"
              >
                Damages
              </th>
              <th
                scope="col"
                className="hidden px-6 py-3 text-left font-bold text-gray-500 uppercase tracking-wider md:table-cell"
              >
                Photos
              </th>
              <th scope="col" className="relative px-6 py-3">
                <span className="sr-only">View</span>
              </th>
            </tr>
          </thead>
          <tbody className="flex-1 sm:flex-none bg-white divide-y divide-gray-200">
            {(storages || [])
              .filter(i => !i.isPart)
              .map((storage, index) =>
                renderLine(
                  storage,
                  index,
                  photos.filter(i => i.storageId === storage.id)
                )
              )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const ConnectedRequestNote = connect(
  (state, props: Props) => {
    const { urlParams } = props;
    const { requestId } = urlParams;

    const request = ((state as any).requests || []).find(
      i => requestId && i.id == requestId
    );

    const storageIds = request && request.request_lines.map(i => i.storageId);

    // const storages =
    //   !!storageIds &&
    //   !!storageIds.length &&
    //   (state as any).storages.filter(i => storageIds.find(j => j === i.id));
    const storages = !!storageIds && !!storageIds.length && (
      (state as any).storages.length
        ? (state as any).storages.filter(i => storageIds.find(j => j === i.id))
        : (state as any).storageParts.allStorages.filter(i => storageIds.find(j => j === i.id))
    );

    const photos =
      storageIds &&
      storageIds.length &&
      (state as any).photos.filter(i =>
        storageIds.find(j => j === i.storageId)
      );

    return {
      requests: (state as any).requests,
      request,
      storages,
      photos,
    };
  },
  dispatch =>
    bindActionCreators(
      {
        ...appActions,
        ...requestActions,
        ...userActions,
        ...photoActions,
        push,
      },
      dispatch
    )
)(RequestNotePage);

export default ConnectedRequestNote;
